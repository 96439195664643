<script setup lang="ts">
defineEmits<{
  (e: "close"): void;
}>();

const { cart, cartItems, isEmpty } = useCart();
</script>

<template>
  <div class="lg:flex lg:flex-row lg:items-start lg:gap-8">
    <div class="flex flex-grow flex-col justify-between overflow-auto">
      <ul role="list">
        <template v-if="!isEmpty" v-for="cartItem in cartItems" :key="cartItem.id">
          <CheckoutCartItem layout="sidecart" :cart-item="cartItem"
                            :voucher-is-favorite-item="cart?.extensions?.voucher?.isFavoriteItem"
                            :favorite-item="cart?.extensions?.voucher?.favoriteItem" />
        </template>
        <p v-else class="text-p">Sie haben noch keine Produkte zu Ihrem Warenkorb hinzugefügt.</p>
      </ul>
    </div>

    <div class="bg-maas-background-grey-light px-4 pb-4 lg:max-w-sm w-full">
      <CheckoutCartSummary />
      <SharedFormButton type="link" to="/checkout" layout="primary" size="big" data-testid="cart-checkout-link" class="hidden lg:block w-full">
        Weiter zur Kasse
      </SharedFormButton>
    </div>
  </div>
</template>
